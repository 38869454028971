import { toTimeObject } from './parseIsoDuration'

export default function durationToNorwegian(duration = '') {
  return duration
    .replace('PT', '')
    .replace('H', 't ')
    .replace('M', 'min ')
    .replace('S', 's')
    .trim()
}

export const durationToNorwegianMinutesPresicion = (duration = '') => {
  const { hours, minutes, seconds } = toTimeObject(duration)
  const hoursPart = hours > 0 ? `${hours} t ` : ''
  const minutesPart = minutes > 0 ? `${minutes} min ` : ''
  const secondsPart = hours === 0 && minutes === 0 && seconds > 0 ? `${seconds} s` : ''

  return `${hoursPart}${minutesPart}${secondsPart}`.trim()
}
