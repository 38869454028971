import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Box from '../../../RadioUI/Box'
import Button from '../../../RadioUI/CustomButton'
import Flex from '../../../RadioUI/Flex'
import Icon from '../../../RadioUI/Icon'
import Portal from '../../../RadioUI/Portal'
import PseudoBox from '../../../RadioUI/PseudoBox'
import Text from '../../../RadioUI/Text'
import { useColors } from '../../../RadioUI/theme/Color'
import { spCategory, trackSnowplowEvent } from '../../../clientMonitoring'
import FINISHED_PERCENTAGE from '../../../common/finishedPercentage'
import { durationToNorwegianMinutesPresicion } from '../../../common/formatDuration'
import { TARGET_TYPE_EPISODE, UP_NEXT_CONTENT_TYPES } from '../../../common/types'
import EpisodeMenu from '../../../components/EpisodeMenu/EpisodeMenu'
import PlayProgressHoverButton from '../../../components/PlayProgressHoverButton'
import AddToQueueButton from '../../../components/Queues/AddToQueueButton'
import { CONTENT_CONTEXTS } from '../../../components/Queues/UpNext/UpNextContext'
import { useIsPlayerPlaying } from '../../../components/StickyPlayer/context/PlayerElementStateContext'
import { useCurrentlyPlayingId } from '../../../components/StickyPlayer/context/PlayerMetadataContext'
import { fetchProgramProgressList } from '../../../redux/personalisation/progressActions'
import AVAILABILITY_STATUSES from '../helpers/availabilityStatuses'
import formatPublishIsoDateString from '../helpers/formatPublishIsoDateString'
import { useEpisodeId, useEpisodeIsLoading } from '../hooks/episodeHooks'
import {
  useEpisodes,
  useEpisodesProgressHref,
  useFirsOfNextPage,
  useLatestEpisodesIsLoading,
  useSeasonEpisodesIsLoading,
  useSeasonIsLoading
} from '../hooks/seasonHooks'
import { useContentType, useSeriesIsLoading, useSeriesTitle } from '../hooks/seriesHooks'
import { createEpisodeShareUrl } from './EpisodeContainer'
import EpisodeListContainerLoadingPlaceholder from './EpisodeListContainerLoadingPlaceholder'
import EpisodeStatusIcon from './EpisodeStatusIcon'
import ShowMoreButton from './ShowMoreButton'
import VideoPodcastButton from './VideoPodcastButton'

const EpisodeListContainer = props => {
  const dispatch = useDispatch()
  const params = useParams()
  const colors = useColors()
  const episodes = useEpisodes()
  const seasonIsLoading = useSeasonIsLoading()
  const seriesIsLoading = useSeriesIsLoading()
  const episodeIsLoading = useEpisodeIsLoading()
  const latestEpisodesIsLoading = useLatestEpisodesIsLoading()
  const seasonEpisodesIsLoading = useSeasonEpisodesIsLoading()
  const contentType = useContentType()
  const episodeIdFromState = useEpisodeId()
  const episodesProgressHref = useEpisodesProgressHref()
  const currentlyPlayingId = useCurrentlyPlayingId()
  const playerIsPlaying = useIsPlayerPlaying()
  const firstOfNextPageEpisodeId = useFirsOfNextPage()
  const firstOfNextPageRef = useRef()
  const [isHiddenState, setIsHiddenState] = useState(true)
  const [pickedEpisode, setPickedEpisode] = useState(null)
  const seriesTitle = useSeriesTitle()

  useLayoutEffect(() => {
    if (firstOfNextPageRef.current && firstOfNextPageEpisodeId) {
      firstOfNextPageRef.current.focus()
    }
  }, [firstOfNextPageEpisodeId])

  useEffect(() => {
    if (episodesProgressHref) {
      dispatch(fetchProgramProgressList({ href: episodesProgressHref }))
    }
  }, [episodesProgressHref, dispatch])

  const seasonLoaded = !seriesIsLoading && !seasonIsLoading && !seasonEpisodesIsLoading && !latestEpisodesIsLoading
  if (!episodes && seasonLoaded) return null

  if (!seasonLoaded) {
    return <EpisodeListContainerLoadingPlaceholder />
  }

  return (
    <Box {...props}>
      <Box as="ul" pb="6">
        {episodes.map(episode => {
          const urlRoot = `/${params.contentType}`
          const urlSeasonIdPart = params.seasonId ? `/sesong/${params.seasonId}` : ''

          const to = `${urlRoot}/${params.seriesId}${urlSeasonIdPart}/${episode.episodeId}`
          const episodeIsAvailable =
            episode.availability.status === AVAILABILITY_STATUSES.AVAILABLE ||
            episode.availability.status === AVAILABILITY_STATUSES.EXPIRES
          const active = !episodeIsLoading && episode.episodeId === episodeIdFromState
          const episodeIsPlaying = currentlyPlayingId === episode.episodeId && playerIsPlaying
          const isPodcast = params.contentType === 'podkast'
          const episodeType = isPodcast ? UP_NEXT_CONTENT_TYPES.PODCAST_EPISODE : UP_NEXT_CONTENT_TYPES.PROGRAMS
          const episodeId = isPodcast ? `${params.seriesId}|${episode.episodeId}` : episode.episodeId || params.seriesId

          return (
            <Box
              as="li"
              key={episode.episodeId}
              pos="relative"
              css={getStyle(episode, active, episodeIsPlaying, colors)}
              data-test={`active=${active}`}
            >
              <PseudoBox
                as={Link}
                display="flex"
                flexDir="column"
                type={null}
                w="100%"
                h="120px"
                borderRadius="4px"
                p="4"
                mb="2px"
                justifyContent="space-between"
                id={episode.episodeId}
                to={to}
                ref={episode.episodeId === firstOfNextPageEpisodeId ? firstOfNextPageRef : null}
                data-test="episode"
                onClick={() => trackSnowplowEvent(spCategory.SeriesPage, 'Episode')}
              >
                <Box mb="1rem">
                  <Text
                    fontSize="lg"
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    data-test="episodeListTitle"
                    mb="0.25rem"
                    opacity={episodeIsAvailable ? 1 : 0.7}
                  >
                    {episode.titles && episode.titles.title}
                  </Text>
                  {episodeIsAvailable && (
                    <Text lineHeight="16px" fontWeight="normal" fontSize="sm" opacity={0.7}>
                      {episode.availability.hasLabel
                        ? episode.availability.label
                        : `${formatPublishIsoDateString(episode.date)}`}
                    </Text>
                  )}
                </Box>
                {!episodeIsAvailable && (
                  <Box>
                    <Box d="flex" alignItems="center">
                      <EpisodeStatusIcon availabilityStatus={episode.availability.status} />
                      <Text lineHeight="16px" fontWeight="normal" fontSize="sm" opacity={0.7} ml="0.5rem">
                        {episode.availability.hasLabel
                          ? episode.availability.label
                          : `${formatPublishIsoDateString(episode.date)}`}
                      </Text>
                    </Box>
                  </Box>
                )}
              </PseudoBox>

              {episodeIsAvailable && (
                <Box d="flex" alignItems="center" position="absolute" bottom="1rem" left="1rem">
                  <PlayProgressHoverButton
                    episodeId={episode.episodeId}
                    seriesId={params.seriesId}
                    seasonId={params.seasonId}
                    contentType={contentType}
                    percentage={episode.percentage}
                    startPoint={episode.startPoint}
                    contentContext={CONTENT_CONTEXTS.SERIES}
                    finished={episode.finished}
                    title={episode.titles && episode.titles.title}
                    onPlay={() => {
                      trackSnowplowEvent(spCategory.SeriesPage, 'play')
                    }}
                  />
                </Box>
              )}
              {episodeIsAvailable && (
                <Flex
                  className="EpisodeMenuItemSiblingWrapper"
                  position="absolute"
                  bottom="1.7rem"
                  left="4rem"
                  gap="0.25rem"
                  alignItems="center"
                  fontSize="sm"
                  pointerEvents="none"
                >
                  {episode.clipId && <Text opacity={0.7}>{`Med video • `}</Text>}
                  <Text opacity={0.7}>
                    {episode.availability.hasLabel
                      ? episode.availability.label
                      : `${durationToNorwegianMinutesPresicion(episode.duration)} `}
                  </Text>
                  <Icon className="EpisodeFinishedIcon" iconId="nrk-check" />
                </Flex>
              )}
              <Flex
                className="EpisodeMenuItemSiblingWrapper"
                position="absolute"
                bottom="1rem"
                right="1rem"
                alignItems="center"
                pointerEvents="auto"
              >
                <VideoPodcastButton
                  clipId={episode.clipId}
                  episodeId={episode.episodeId}
                  seasonId={params.seasonId}
                  seriesId={params.seriesId}
                  seekTo={episode.startPoint}
                />
                <AddToQueueButton episodeId={episodeId} episodeType={episodeType} />
                <Button
                  aria-label="Åpne episode menyen"
                  iconId="nrk-more"
                  className="EpisodeActionButton"
                  variant="transparent"
                  onClick={e => {
                    e.preventDefault()
                    setPickedEpisode(episode)
                    setIsHiddenState(!isHiddenState)
                    trackSnowplowEvent(spCategory.SeriesPage, 'ActionMenuButtonTapped', 'Episode')
                  }}
                >
                  <Icon className="EpisodeMenuIcon" size="sm" fontSize="1rem" iconId="nrk-more" color="currentColor" />
                </Button>
              </Flex>
            </Box>
          )
        })}
      </Box>

      <ShowMoreButton d="block" mx="auto" />
      {!!pickedEpisode && (
        <Portal>
          <EpisodeMenu
            isHiddenState={isHiddenState}
            setIsHiddenState={setIsHiddenState}
            seriesId={params.seriesId}
            seriesTitle={seriesTitle}
            episodeId={pickedEpisode.episodeId}
            episodeTitle={pickedEpisode.titles && pickedEpisode.titles.title}
            duration={pickedEpisode.duration}
            targetType={
              pickedEpisode.episodeId && contentType === 'podcast'
                ? TARGET_TYPE_EPISODE.EPISODE[1]
                : TARGET_TYPE_EPISODE.EPISODE[0]
            }
            linkUrl={createEpisodeShareUrl(contentType, params.seriesId, params.seasonId, pickedEpisode.episodeId)}
            episodeIsAvailable={
              pickedEpisode.availability.status === AVAILABILITY_STATUSES.AVAILABLE ||
              pickedEpisode.availability.status === AVAILABILITY_STATUSES.EXPIRES
            }
          />
        </Portal>
      )}
    </Box>
  )
}

EpisodeListContainer.propTypes = {
  programId: PropTypes.string
}

function getStyle(episode, active, episodeIsPlaying, colors) {
  const episodeFinished = episode.percentage === FINISHED_PERCENTAGE || episode.finished
  return css`
    a {
      background: ${active ? colors.main.light : colors.main.mediumLight10};
      color: ${active ? colors.main.dark : colors.main.contrastLight};

      ~ .EpisodeMenuItemSiblingWrapper .EpisodeActionButton {
        background: transparent;
      }

      ~ .EpisodeMenuItemSiblingWrapper > * {
        color: ${active ? colors.main.dark : colors.main.contrastLight};
      }

      @media all and (min-width: 1024px) {
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          background: ${active ? colors.main.light : colors.main.mediumLight20};
        }
      }

      &:active {
        background: ${colors.main.mediumLight40};
        color: ${colors.main.light};

        ~ .EpisodeActionButton {
          background: transparent;
        }

        ~ .EpisodeMenuItemSiblingWrapper > * {
          color: ${colors.main.light};
        }
      }
    }

    .PlayProgressHoverButton {
      .PlayProgressHoverButtonProgressCircle {
        circle {
          stroke-width: 2;
        }
      }
    }

    .EpisodeFinishedIcon {
      display: ${episodeFinished ? 'block' : 'none'};
      color: inherit;
    }

    .EpisodeActionButton {
      width: 30px;
      height: 30px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.2);
        color: ${active ? colors.main.dark : colors.main.contrastLight};
      }
    }

    .EpisodeActionIcon {
      width: 24px;
      height: 24px;
    }
  `
}

export default EpisodeListContainer
