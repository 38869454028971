import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../RadioUI/Box'
import Icon from '../../../RadioUI/Icon'
import PseudoBox from '../../../RadioUI/PseudoBox'
import { useColors } from '../../../RadioUI/theme/Color'

const PlaylitsItem = ({ displayStartTime, artist, track, ...restProps }) => {
  const colors = useColors()

  return (
    <PseudoBox d="flex" color={colors.main.contrastLight} opacity={0.5} {...restProps}>
      <Box className="left-side-icon-ip" as="span" d="flex" flex="0 0 24px" bg="inherit" justifyContent="center" w="6">
        <Icon iconId="nrk-note-1" size="sm" />
      </Box>
      <Box as="time" pr="4" minW="80px" textAlign="right" dateTime={displayStartTime}>
        {displayStartTime}
      </Box>
      <Box as="span" textAlign="left" wordBreak="break-word">
        {artist} - {track}
      </Box>
    </PseudoBox>
  )
}

PlaylitsItem.propTypes = {
  displayStartTime: PropTypes.string,
  artist: PropTypes.string,
  track: PropTypes.string
}

export default PlaylitsItem
