import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../RadioUI/CustomButton'
import { QueueContext } from './QueueContext'
import { addToQueue, removeFromQueue } from '../../apiClients/personalisation'
import { AddedToQueueIconSvg, PlayLaterIconSvg } from './QueueIcons'
import { useIsLoggedIn } from '../Auth/AuthContext'
import LoginForQueue from './LoginForQueue'
import QueueToast from './QueueToast'

const noop = () => {}

const AddToQueueButton = ({ episodeId, episodeType, onAddToQueue = noop, onRemoveFromQueue = noop, ...restProps }) => {
  const isLoggedIn = useIsLoggedIn()
  const { queue, setQueue } = useContext(QueueContext)
  const [isLoginPopUpHidden, setIsLoginPopUpHidden] = useState(true)
  const [isMessageHiddenState, setIsMessageHiddenState] = useState(true)

  if (!isLoggedIn) {
    return (
      <>
        <Button
          className="EpisodeActionButton"
          aria-label="Legg til i kø"
          variant="transparent"
          onClick={e => {
            e.preventDefault()
            setIsLoginPopUpHidden(false)
          }}
          {...restProps}
        >
          <PlayLaterIconSvg className="EpisodeActionIcon" />
        </Button>
        <LoginForQueue isHidden={isLoginPopUpHidden} setIsHidden={setIsLoginPopUpHidden} />
      </>
    )
  }
  if (isLoggedIn && queue && !queue.find(qu => qu.id === episodeId)) {
    return (
      <>
        <Button
          className="EpisodeActionButton"
          aria-label="Legg til i kø"
          variant="transparent"
          onClick={event => {
            event.preventDefault()
            onAddToQueue()
            addToQueue(episodeId, episodeType, 'last').then(e => {
              setQueue(e.queue)
              setIsMessageHiddenState(false)
            })
          }}
          {...restProps}
        >
          <PlayLaterIconSvg className="EpisodeActionIcon" />
        </Button>
        <QueueToast isHidden={isMessageHiddenState} setIsHidden={setIsMessageHiddenState} />
      </>
    )
  } else if (isLoggedIn && queue && queue.find(qu => qu.id === episodeId)) {
    return (
      <>
        <Button
          className="EpisodeActionButton"
          aria-label="Fjern fra kø"
          variant="transparent"
          onClick={event => {
            event.preventDefault()
            onRemoveFromQueue()
            removeFromQueue(episodeId, episodeType).then(e => setQueue(e.queue))
          }}
          {...restProps}
        >
          <AddedToQueueIconSvg className="EpisodeActionIcon" />
        </Button>
        <QueueToast isHidden={isMessageHiddenState} setIsHidden={setIsMessageHiddenState} />
      </>
    )
  }
  return null
}

AddToQueueButton.propTypes = {
  episodeId: PropTypes.string,
  episodeType: PropTypes.string,
  onAddToQueue: PropTypes.func,
  onRemoveFromQueue: PropTypes.func
}

export default AddToQueueButton
