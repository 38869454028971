import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import breakpoints from '../../../RadioUI/theme/breakpoints'
import { css } from '@emotion/react'
import sizes from '../../../RadioUI/theme/sizes'
import Grid from '../../../RadioUI/Grid'
import SMALL_SCREEN_VIEW_TYPES from '../helpers/smallScreenViewTypes'

const EpisodeListAndEpisodeWrapper = forwardRef(({ offsetTop = 0, episodeIsSelected, children, ...restProps }, ref) => {
  const smallScreenViewType = episodeIsSelected ? SMALL_SCREEN_VIEW_TYPES.EPISODE : SMALL_SCREEN_VIEW_TYPES.SEASON
  const showSeasonOnSmall = smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.SEASON
  const showEpisodeOnSmall = smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.EPISODE

  return (
    <Grid ref={ref} css={getStyle(offsetTop, showSeasonOnSmall, showEpisodeOnSmall)} {...restProps}>
      {children}
    </Grid>
  )
})

EpisodeListAndEpisodeWrapper.displayName = 'EpisodeListAndEpisodeWrapper'

EpisodeListAndEpisodeWrapper.propTypes = {
  offsetTop: PropTypes.number,
  episodeIsSelected: PropTypes.bool,
  children: PropTypes.node
}

function getStyle(offsetTop, showSeasonOnSmall, showEpisodeOnSmall) {
  /** emotion styled components uses styles css processor. Stylus currently doesn't support prefixing grid.
   * That is why prefixes are added manually here.
   */
  return css`
    display: -ms-grid;
    position: relative;
    max-width: 100%;
    min-width: 0;
    width: 100%;
    min-height: 0;
    padding-bottom: ${sizes['6']};
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: max-content;
    grid-template-rows: max-content;
    grid-column-gap: 1.5rem;

    @media screen and (min-width: ${breakpoints.md}) {
      -ms-grid-columns: calc(316px) minmax(0, 1fr);
      grid-template-columns: calc(316px) minmax(0, 1fr);
    }

    @media screen and (min-width: ${breakpoints.lg}) {
      column-gap: 2.5rem;
      -ms-grid-columns: 384px 2.5rem minmax(0, 1fr);
      grid-template-columns: 384px minmax(0, 1fr);
    }

    @media screen and (min-width: ${breakpoints.xl}) {
      -ms-grid-columns: 462px minmax(0, 1fr);
      grid-template-columns: 462px minmax(0, 1fr);
    }

    & > *:nth-of-type(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      display: ${showSeasonOnSmall ? 'block' : 'none'};

      @media all and (min-width: ${breakpoints.md}) {
        top: ${offsetTop}px;
        position: sticky;
        display: block;
      }
    }

    & > *:nth-of-type(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      display: ${showEpisodeOnSmall ? 'grid' : 'none'};

      @media all and (min-width: ${breakpoints.md}) {
        top: ${offsetTop}px;
        position: sticky;
        display: grid;
      }
    }
  `
}

export default EpisodeListAndEpisodeWrapper
