const calculateScrollToElementPosition = (scrollTargetElement, offset = 0, bodyElement = document.body) => {
  if (!scrollTargetElement || !bodyElement || !Number.isInteger(offset)) return 0

  const bodyTop = bodyElement.getBoundingClientRect().top
  const scrollTargetElementTop = scrollTargetElement.getBoundingClientRect().top
  const scrollTargetElementPosition = scrollTargetElementTop - bodyTop
  const offsetPosition = scrollTargetElementPosition - offset

  return offsetPosition
}

export default calculateScrollToElementPosition
