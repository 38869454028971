import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '../../../RadioUI/Box'
import { useParams } from 'react-router-dom'
import { useContentType } from '../hooks/seriesHooks'
import PseudoBox from '../../../RadioUI/PseudoBox'
import { useColors } from '../../../RadioUI/theme/Color'
import { MEDIA_TYPES } from '../../../components/StickyPlayer/helpers/mediaTypes'
import { useAudioOrVideoPlayerElement } from '../../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from '../../../components/Queues/UpNext/UpNextContext'

const IndexPoint = ({
  episodeId,
  displayStartTime,
  title,
  startTimeInSeconds,
  isActive = false,
  isInFuture = false,
  contentContext,
  favouriteLevel,
  ...restProps
}) => {
  const { seriesId } = useParams()
  const contentType = useContentType()
  const colors = useColors()
  const { loadAndStartOrPlayFrom } = useAudioOrVideoPlayerElement()
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()

  const onIndexPointClick = useCallback(() => {
    setUpNextContentContext(contentContext)
    setUpNextFavoriteLevel(favouriteLevel)
    loadAndStartOrPlayFrom({
      episodeId,
      seriesId,
      mediaType: contentType === 'podcast' ? MEDIA_TYPES.PODCAST : MEDIA_TYPES.PROGRAM,
      seekTo: startTimeInSeconds
    })
  }, [
    contentType,
    episodeId,
    loadAndStartOrPlayFrom,
    seriesId,
    startTimeInSeconds,
    setUpNextContentContext,
    contentContext,
    setUpNextFavoriteLevel,
    favouriteLevel
  ])

  return (
    <PseudoBox
      as="button"
      type="button"
      d="flex"
      fontWeight="bold"
      onClick={onIndexPointClick}
      data-test="indexPoint"
      alignItems="flex-start"
      color={isActive ? colors.main.light : colors.main.contrastLight}
      opacity={isInFuture ? 0.7 : 1}
      active={isActive}
      pointerEvents={isInFuture ? 'none' : 'auto'}
      {...restProps}
    >
      <Box
        className="left-side-icon-ip"
        as="span"
        w="6"
        d="flex"
        flex="0 0 24px"
        bg="inherit"
        alignItems="center"
        justifyContent="center"
      >
        ▪︎
      </Box>
      <Box as="time" pr="4" minW="80px" textAlign="right" dateTime={displayStartTime}>
        {displayStartTime}
      </Box>
      <Box as="span" textAlign="left" wordBreak="break-word">
        {title}
      </Box>
    </PseudoBox>
  )
}

IndexPoint.propTypes = {
  displayStartTime: PropTypes.string,
  title: PropTypes.string,
  startTimeInSeconds: PropTypes.any,
  episodeId: PropTypes.string,
  isActive: PropTypes.bool,
  isInFuture: PropTypes.bool,
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default IndexPoint
