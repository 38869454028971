import React from 'react'
import Box from '../../../RadioUI/Box'
import CustomButton from '../../../RadioUI/CustomButton'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'

// export const episodeListButtonHeight = '2rem + 1.25rem + 1.25rem + 1px + 1px'
export const episodeListButtonHeight = '124px'

const EpisodeListContainerLoadingPlaceholder = () => {
  return (
    <Box>
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
      <CustomButton as={LoadingPlaceholder} type={null} delay={300} mt="1" h={`calc(${episodeListButtonHeight})`} />
    </Box>
  )
}

export default EpisodeListContainerLoadingPlaceholder
