import React from 'react'
import PropTypes from 'prop-types'
import { useParams, Link } from 'react-router-dom'
import Button from '../../../RadioUI/Button'
import { useEpisodeAssociatedSeasonId } from '../hooks/episodeHooks'

const SeasonBackLink = ({ children, ...restProps }) => {
  const params = useParams()
  const episodeAssociatedSeasonId = useEpisodeAssociatedSeasonId()

  if (!params.contentType || !params.seriesId) return null
  const seasonId = params.seasonId || episodeAssociatedSeasonId
  const seasonPart = seasonId ? `/sesong/${seasonId}` : ''
  return (
    <Button
      as={Link}
      type={null}
      to={`/${params.contentType}/${params.seriesId}${seasonPart}`}
      data-test="returnToSeriesMobile"
      {...restProps}
    >
      {children}
    </Button>
  )
}

SeasonBackLink.propTypes = {
  children: PropTypes.node
}

export default SeasonBackLink
