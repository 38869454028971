import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../RadioUI/Box'
import PlaylitsItem from './PlaylitsItem'
import IndexPoint from './IndexPoint'
import ShareIndexpoint from './ShareIndexpoint'
import Flex from '../../../RadioUI/Flex'
import { css } from '@emotion/react'
import sizes from '../../../RadioUI/theme/sizes'
import radii from '../../../RadioUI/theme/radii'
import shadows from '../../../RadioUI/theme/shadows'

const EpisodeIndexPoints = ({ indexPoints, episodeId, contentContext, favouriteLevel, ...restProps }) => {
  if (!Array.isArray(indexPoints)) return null

  return (
    <Box as="ul" {...restProps}>
      {indexPoints.map(({ type, title, description, displayStartTime, startTimeInSeconds }) => {
        return (
          <Flex
            as="li"
            key={`${displayStartTime}_${title}`}
            css={css`
              border-radius: ${radii.md};
              align-items: center;
              padding-right: ${sizes['4']};
              ${type !== 'Music' &&
                `
              &:focus-within {
                box-shadow: ${shadows.outline};
              }
              &:hover,
              &:focus {
                opacity: 0.7;
              }
              `}
            `}
          >
            {type === 'Music' ? (
              <PlaylitsItem
                flex="1 0 auto"
                p="4"
                maxW="calc(100% - 60px)"
                episodeId={episodeId}
                displayStartTime={displayStartTime}
                artist={description}
                track={title}
                startTimeInSeconds={startTimeInSeconds}
                contentContext={contentContext}
                favouriteLevel={favouriteLevel}
                _active={{ outline: 'none' }}
                _focus={{ outline: 'none' }}
              />
            ) : (
              <>
                <IndexPoint
                  flex="1 0 auto"
                  maxW="calc(100% - 60px)"
                  p="4"
                  episodeId={episodeId}
                  displayStartTime={displayStartTime}
                  title={title}
                  description={description}
                  startTimeInSeconds={startTimeInSeconds}
                  contentContext={contentContext}
                  favouriteLevel={favouriteLevel}
                  _focus={{ outline: 'none' }}
                />
                <ShareIndexpoint
                  timestampSeconds={startTimeInSeconds}
                  displayStartTime={displayStartTime}
                  title={title}
                  ml="auto"
                />
              </>
            )}
          </Flex>
        )
      })}
    </Box>
  )
}

EpisodeIndexPoints.propTypes = {
  indexPoints: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      displayStartTime: PropTypes.string,
      urlTimestamp: PropTypes.string,
      startTimeInSeconds: PropTypes.number
    })
  ),
  episodeId: PropTypes.string,
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default EpisodeIndexPoints
