import React from 'react'
import PropTypes from 'prop-types'
import Box from '../RadioUI/Box'
import { useColors } from '../RadioUI/theme/Color'

function progressToStrokeOffset(percentage, circumference) {
  return circumference - (percentage / 100) * circumference
}

function calculateCircle(buttonSize) {
  const radius = buttonSize / 2 - 1
  const circumference = 2 * Math.PI * radius
  const centerxy = buttonSize / 2
  return {
    radius,
    circumference,
    cx: centerxy,
    cy: centerxy
  }
}

const PlayProgressHoverButtonProgressCircle = ({ percentage = 0, variant, variantColor }) => {
  const colors = useColors()[variantColor]
  const buttonSize = 40

  if (variant === 'playpause') {
    const { radius, circumference, cx, cy } = calculateCircle(buttonSize)
    return (
      <Box as="svg" position="absolute" w={'100%'} h={'100%'} top="0" className="PlayProgressHoverButtonProgressCircle">
        <Box
          as="circle"
          stroke="currentColor"
          fill="rgba(0, 0, 0, 0)"
          r={radius}
          cx={cx}
          cy={cy}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progressToStrokeOffset(percentage, circumference)}
          transform="rotate(-90deg)"
          transformOrigin="50% 50%"
          transition="stroke-dashoffset 0.35s"
        />
      </Box>
    )
  } else if (variant === 'light') {
    const outlineOffset = 5
    const { radius, circumference, cx, cy } = calculateCircle(buttonSize + outlineOffset)
    return (
      <>
        <Box
          as="svg"
          position="absolute"
          w={`calc(100% + (${outlineOffset}px * 2))`}
          h={`calc(100% + (${outlineOffset}px * 2))`}
          top="0"
          left="0"
          transform={`translate(-${outlineOffset}px, -${outlineOffset}px)`}
          className="PlayProgressHoverButtonProgressCircle"
        >
          <Box
            as="circle"
            stroke={colors.light}
            fill="rgba(0, 0, 0, 0)"
            r={radius}
            cx={cx}
            cy={cy}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={progressToStrokeOffset(percentage, circumference)}
            transform="rotate(-90deg)"
            transformOrigin="50% 50%"
            transition="stroke-dashoffset 0.35s"
          />
        </Box>
      </>
    )
  }
}

PlayProgressHoverButtonProgressCircle.propTypes = {
  percentage: PropTypes.number,
  variant: PropTypes.string,
  variantColor: PropTypes.string
}

export default PlayProgressHoverButtonProgressCircle
