import React from 'react'
import { useEpisodesPageIsLoading, useEpisodesNextPageHref, useShowSeasonNotLatest } from '../hooks/seasonHooks'
import Button from '../../../RadioUI/Button'
import { useDispatch } from 'react-redux'
import { fetchLatestEpisodesPage, fetchSeasonPage } from '../actions'
import { trackSnowplowEvent, spCategory } from '../../../clientMonitoring'

const ShowMoreButton = props => {
  const dispatch = useDispatch()
  const nextPageHref = useEpisodesNextPageHref()
  const seasonPageIsLoading = useEpisodesPageIsLoading()
  const showingSeason = useShowSeasonNotLatest()

  if (!nextPageHref) {
    return null
  }
  return (
    <Button
      disabled={seasonPageIsLoading}
      onClick={() => {
        trackSnowplowEvent(spCategory.SeriesPage, 'ShowMoreEpisodes')
        if (showingSeason) {
          dispatch(fetchSeasonPage({ href: nextPageHref }))
        } else {
          dispatch(fetchLatestEpisodesPage({ href: nextPageHref }))
        }
      }}
      {...props}
      data-test="ShowMore"
    >
      {seasonPageIsLoading ? 'Henter flere episoder' : 'Vis flere episoder'}
    </Button>
  )
}

ShowMoreButton.propTypes = {}

export default ShowMoreButton
