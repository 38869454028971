import React from 'react'
import CoreDialog from '@nrk/core-dialog/jsx'
import Box from '../../RadioUI/Box'
import Text from '../../RadioUI/Text'
import PropTypes from 'prop-types'
import Portal from '../../RadioUI/Portal'
import modalStyle from '../../RadioUI/Portal/modalStyle'
import Button from '../../RadioUI/Button'
import { useColors } from '../../RadioUI/theme/Color'
import IconButton from '../../RadioUI/IconButton'
import { useAuthContext } from '../Auth/AuthContext'

const LoginForQueue = ({ isHidden, setIsHidden }) => {
  const colors = useColors()
  const { login } = useAuthContext()

  if (isHidden) return null

  return (
    <Portal>
      <CoreDialog
        css={modalStyle}
        onDialogToggle={event => {
          event.preventDefault()
          setIsHidden(!!event.target.hidden)
        }}
        hidden={isHidden}
      >
        <Box
          bg={colors.personal.light}
          color={colors.personal.mediumLight10}
          rounded="md"
          w="313px"
          p="1.5rem"
          position="relative"
        >
          <Text mb="16px" size="xl" weight="bold">
            Logg på
          </Text>
          <Text mb="16px" size="md">
            Du må være innlogget for å legge programmet i køen.
          </Text>
          <Button
            variantColor="personal"
            variant="login"
            rightIcon="nrk-open-in-new"
            onClick={() => {
              login()
            }}
          >
            Logg på
          </Button>

          <IconButton
            iconId="nrk-close"
            onClick={() => setIsHidden(true)}
            aria-label="Lukk innloggingsbeskjeden"
            pos="absolute"
            top="12px"
            right="12px"
            fontSize="sm"
            w="24px"
            h="24px"
            variantColor="personal"
            variant="close"
          />
        </Box>
      </CoreDialog>
    </Portal>
  )
}

LoginForQueue.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  setIsHidden: PropTypes.func.isRequired
}

export default LoginForQueue
