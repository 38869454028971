import isToday from 'date-fns/is_today'
import isYesterday from 'date-fns/is_yesterday'
import isTomorrow from 'date-fns/is_tomorrow'
import localeFormat from '../../../common/localeFormat'

const formatPublishIsoDateString = isoDate => {
  if (!isoDate) {
    return ''
  }

  const date = new Date(isoDate)

  if (!date.getTime()) {
    return ''
  }

  if (isToday(date) || isYesterday(date) || isTomorrow(date)) {
    return localeFormat(date, 'DD.MMM')
  } else {
    return localeFormat(date, 'DD.MM.YYYY')
  }
}

export default formatPublishIsoDateString
