import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../RadioUI/CustomButton'
import Icon from '../RadioUI/Icon'
import FINISHED_PERCENTAGE from '../common/finishedPercentage'
import PlayProgressHoverButtonProgressCircle from './PlayProgressHoverButtonProgressCircle'
import { usePlayerMetadata } from './StickyPlayer/context/PlayerMetadataContext'
import { usePlayerElementState } from './StickyPlayer/hooks/useAudioAndVideoPlayerElementState'
import { useAudioOrVideoPlayerElement } from './StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { mediaTypeFrom } from '../pages/Series/helpers/mediaTypeFrom'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from './Queues/UpNext/UpNextContext'

const noop = () => {}

const PlayProgressHoverButton = ({
  episodeId,
  seasonId,
  seriesId,
  contentType,
  percentage = 0,
  finished = false,
  startPoint,
  title = 'episode',
  onPlay = noop,
  onPause = noop,
  variant = 'playpause',
  variantColor = 'main',
  contentContext,
  favouriteLevel
}) => {
  const { episodeId: episodeIdFromMetadata, mediaType, clipId } = usePlayerMetadata()
  const { isPlaying: playerIsPlaying } = usePlayerElementState()
  const { loadAndStartOrTogglePlayPause } = useAudioOrVideoPlayerElement()
  const episodeIsInPlayer = episodeIdFromMetadata === episodeId
  const episodeIsPlaying = episodeIsInPlayer && playerIsPlaying
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()

  const episodeFinished = percentage === FINISHED_PERCENTAGE || finished

  const onClickCB = useCallback(
    e => {
      setUpNextContentContext(contentContext)
      setUpNextFavoriteLevel(favouriteLevel)
      loadAndStartOrTogglePlayPause({
        episodeId,
        clipId,
        seriesId,
        seasonId,
        mediaType: mediaTypeFrom(contentType, mediaType, episodeIdFromMetadata, episodeId),
        seekTo: startPoint
      })
    },
    [
      clipId,
      contentType,
      episodeId,
      episodeIdFromMetadata,
      loadAndStartOrTogglePlayPause,
      mediaType,
      seasonId,
      seriesId,
      startPoint,
      setUpNextContentContext,
      contentContext,
      setUpNextFavoriteLevel,
      favouriteLevel
    ]
  )

  return (
    <CustomButton
      aria-label={episodeIsPlaying ? `Pause ${title}` : `Spill av ${title}`}
      title={episodeIsPlaying ? `Pause ${title}` : `Spill av ${title}`}
      px="0"
      py="0"
      w={variant === 'light' ? '35px' : '40px'}
      h={variant === 'light' ? '35px' : '40px'}
      pos="relative"
      borderRadius="50%"
      onClick={() => {
        episodeIsPlaying ? onPause() : onPlay()
        onClickCB()
      }}
      className="PlayProgressHoverButton"
      data-test="playPauseHoverList"
      variant={variant}
      variantColor={variantColor}
    >
      <PlayProgressHoverButtonProgressCircle
        percentage={episodeFinished ? 100 : percentage}
        variant={variant}
        variantColor={variantColor}
      />
      <Icon
        className="PlayPauseIcon"
        pos="absolute"
        size="xs"
        top="calc(50% - 7.5px)"
        left="calc(50% - 7.5px)"
        iconId={episodeIsPlaying ? 'nrk-media-pause' : 'nrk-media-play'}
        color="currentColor"
      />
    </CustomButton>
  )
}

PlayProgressHoverButton.propTypes = {
  episodeId: PropTypes.string,
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  contentType: PropTypes.string,
  percentage: PropTypes.number,
  finished: PropTypes.bool,
  startPoint: PropTypes.number,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.string,
  variantColor: PropTypes.string,
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default PlayProgressHoverButton
