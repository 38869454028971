import React from 'react'
import { css } from '@emotion/react'
import CoreTabs from '@nrk/core-tabs/jsx'
import { useSeriesIsLoading } from '../hooks/seriesHooks'
import { useSeasonIsLoading } from '../hooks/seasonHooks'
import {
  useEpisodeIsLoading,
  useEpisodeId,
  useEpisodeDescription,
  useEpisodeImage,
  useIndexPointsAndPlaylist
} from '../hooks/episodeHooks'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'
import Box from '../../../RadioUI/Box'
import CustomButton from '../../../RadioUI/CustomButton'
import EpisodeImage from './EpisodeImage'
import EpisodeDescription from './EpisodeDescription'
import EpisodeIndexPoints from './EpisodeIndexPoints'
import Grid from '../../../RadioUI/Grid'
import PseudoBox from '../../../RadioUI/PseudoBox'
import { useColors } from '../../../RadioUI/theme/Color'
import PropTypes from 'prop-types'

const EpisodeTabsContainer = ({ contentContext, favouriteLevel }) => {
  const seriesIsLoading = useSeriesIsLoading()
  const seasonIsLoading = useSeasonIsLoading()
  const episodeIsLoading = useEpisodeIsLoading()
  const episodeId = useEpisodeId()
  const episodeDescription = useEpisodeDescription()
  const episodeImage = useEpisodeImage()
  const indexPoints = useIndexPointsAndPlaylist()
  const colors = useColors()

  const loadingDone = !seriesIsLoading && !seasonIsLoading && !episodeIsLoading

  if (!episodeId && loadingDone) return null

  if (!loadingDone) {
    return (
      <>
        <LoadingPlaceholder delay={300} height="2.5rem" minWidth="100%" />
        <LoadingPlaceholder delay={300} height="sm" minWidth="100%" />
      </>
    )
  }

  const hasIndexPoints = indexPoints.length > 0
  const hasDescription = !!episodeDescription || !!episodeImage.src
  const shouldRenderTabs = [hasIndexPoints, hasDescription].filter(has => has).length > 1

  if (!shouldRenderTabs) {
    return (
      <Box>
        {hasDescription && (
          <>
            <EpisodeDescription>{episodeDescription}</EpisodeDescription>
            <EpisodeImage src={episodeImage.src} srcSet={episodeImage.srcSet} />
          </>
        )}

        {hasIndexPoints && (
          <EpisodeIndexPoints
            indexPoints={indexPoints}
            contentContext={contentContext}
            favouriteLevel={favouriteLevel}
          />
        )}
      </Box>
    )
  }

  return (
    <Grid
      gridRowGap="4"
      css={css`
        button[aria-selected='true'] {
          background: ${colors.main.light};
          color: ${colors.main.dark};
        }
      `}
    >
      <CoreTabs>
        <CustomButton data-test="programInfoTab">Programinformasjon</CustomButton>
        <CustomButton data-test="indexPointsTab" ml="2">
          Tidspunkter
        </CustomButton>
      </CoreTabs>
      <PseudoBox _focus={{ outline: 'none' }}>
        <EpisodeDescription>{episodeDescription}</EpisodeDescription>
        <EpisodeImage src={episodeImage.src} srcSet={episodeImage.srcSet} />
      </PseudoBox>
      <EpisodeIndexPoints indexPoints={indexPoints} episodeId={episodeId} hidden _focus={{ outline: 'none' }} />
    </Grid>
  )
}

EpisodeTabsContainer.propTypes = {
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default EpisodeTabsContainer
