import React from 'react'
import PropTypes from 'prop-types'
import clientConfig from '../../../clientConfig'
import Share from '../../../RadioUI/Share'
import { useEpisodeAssociatedSeriesId, useEpisodeAssociatedSeasonId, useEpisodeId } from '../hooks/episodeHooks'
import { useContentType } from '../hooks/seriesHooks'
import toUrlTimestamp from '../../../RadioUI/Share/helpers/toUrlTimestamp'
import toTime from '../../../RadioUI/Share/helpers/toTime'

const createEpisodeShareUrl = (contentType, seriesId, seasonId, episodeId, timestampSeconds) => {
  if (!contentType || !seriesId || !episodeId) return null
  const seasonPart = seasonId ? `/sesong/${seasonId}` : ''
  const contentTypePart = contentType === 'podcast' ? '/podkast' : '/serie'
  const timestamp = `#t=${toUrlTimestamp(toTime(timestampSeconds))}`

  return `https://${clientConfig.HOST}${contentTypePart}/${seriesId}${seasonPart}/${episodeId}${timestamp}`
}

const ShareIndexpoint = ({ timestampSeconds, displayStartTime, title, ...restProps }) => {
  const associatedSeriesId = useEpisodeAssociatedSeriesId()
  const associatedSeasonId = useEpisodeAssociatedSeasonId()
  const episodeId = useEpisodeId()
  const contentType = useContentType()
  const url = createEpisodeShareUrl(contentType, associatedSeriesId, associatedSeasonId, episodeId, timestampSeconds)

  if (!url) return null

  return (
    <Share
      url={url}
      aria-label={`Del episode fra dette ${displayStartTime}`}
      modalHeader="Del tidspunkt"
      data-test="shareIndexpoint"
      px="2"
      py="2"
      variant="transparent"
      {...restProps}
    />
  )
}

ShareIndexpoint.propTypes = {
  timestampSeconds: PropTypes.number,
  title: PropTypes.string,
  displayStartTime: PropTypes.string
}

export default ShareIndexpoint
