import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _isEqual from 'lodash/isEqual'
import SMALL_SCREEN_VIEW_TYPES from '../helpers/smallScreenViewTypes'

const useSmallScreenToogle = () => {
  const params = useParams()
  const smallScreenViewType = params.programId ? SMALL_SCREEN_VIEW_TYPES.EPISODE : SMALL_SCREEN_VIEW_TYPES.SEASON
  const [toggleState, setState] = useState({
    showSeasonOnSmall: smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.SEASON,
    showEpisodeOnSmall: smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.EPISODE
  })

  useEffect(() => {
    const nextState = {
      showSeasonOnSmall: smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.SEASON,
      showEpisodeOnSmall: smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.EPISODE
    }
    setState(prevState => {
      if (!_isEqual(prevState, nextState)) {
        return {
          showSeasonOnSmall: smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.SEASON,
          showEpisodeOnSmall: smallScreenViewType === SMALL_SCREEN_VIEW_TYPES.EPISODE
        }
      }
      return prevState
    })
  }, [smallScreenViewType])

  return toggleState
}

export default useSmallScreenToogle
