import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../../RadioUI/Image'
import AspectRatioBox from '../../../RadioUI/AspectRatioBox'

const EpisodeImage = ({ src, srcSet }) => {
  if (!src) return null

  return (
    <AspectRatioBox ratio={16 / 9} scale center>
      <Image src={src} srcSet={srcSet} useIsInView={false} borderRadius="6px" />
    </AspectRatioBox>
  )
}

EpisodeImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string
}

export default EpisodeImage
